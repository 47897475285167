<template>
  <div>
    <div class="modal fade" id="modalViews" data-backdrop="static" data-keyboard="false" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered containerUpload" role="document">
        <div class="modal-content containerMain">
          <div class="modal-header pb-0">
             <div class="menu-close" data-dismiss="modal">
                <label id="btnClose">
                  <span></span>
                  <span></span>
                  <span></span>
                </label>
              </div>
          </div>
          <div class="modal-body pb-0">
            <div class="main" v-if="item.link || image64">
                <div v-if="image64">
                  <img :src="image64" :alt="image64" v-if="image64 !== 'null'">
                  <h2 v-else> Esse arquivo não pode ser visualizado </h2>
                  <h1>{{item.name}}</h1>
                  <p>{{item.description}}</p>
                </div>
                <div v-else>
                  <iframe class="iframe" :src="replaceString(item.link, '/watch?v=', '/embed/')" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                  <div>
                    <h1>{{item.name}}</h1>
                    <p>{{item.description}}</p>
                </div>
              </div>
            </div>
            <div class="spinner" v-else>
                <half-circle-spinner
                  :animation-duration="1000"
                  :size="80"
                  color="#f38235"
                />    
              </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { HalfCircleSpinner } from 'epic-spinners'
export default {
  props: ['item', 'image64'],
   components: {
    HalfCircleSpinner
  },
}
</script>

<style scoped>
.menu,
.menu-close {
  height: 100%;
  width: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
}

#checkbox-menu,
#btnClose {
  cursor: pointer;
  position: relative;
  display: block;
  height: 22px;
  width: 30px;
  margin: 0;
}

#btnClose span {
  position: absolute;
  display: block;
  border-radius: 30px;
  background: orange;
  transition: 0.25s ease-in-out;
  width: 36px;
  border: 2px solid orange;
}


#btnClose span:nth-child(1) {
  transform: rotate(-45deg);
  top: 8px;
}

#btnClose span:nth-child(2) {
  opacity: 0;
}

#btnClose span:nth-child(3) {
  transform: rotate(45deg);
  top: 8px;
}

#modalViews .containerUpload {
  max-width: 761px !important;
  width: 760px !important;
}

#modalViews .containerMain {
  background: #FFFFFF;
  box-shadow: 0px 0px 26px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
}

#modalViews .modal-header {
  border: none;
  display: flex;
  flex-direction: row-reverse;
}

.labelTitle {
  color: #A5A6AD !important;
} 

#modalViews h1 {
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 33px;
  color: #2474B2;
}

#modalViews .main > div {
  display: flex;
  flex-direction: column;
}

#modalViews .main .checkContainer{
  display: flex;
}

#modalViews .main label {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #797984;
  cursor: pointer;
}

#modalViews .main input {
  background: #FFFFFF;
  border: 1px solid #98C4E6;
  box-sizing: border-box;
  border-radius: 6px;
  height: 42px;
  padding: 10px;
  margin-bottom: 10px;
  outline: none;
}

.checkContainer div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0px 20px 10px 0;
}

.checkContainer div label{
  margin-left: 5px;
}

h1 {
  text-align: inherit;
  margin: 20px 0;
  border-bottom: 1px solid #98C4E6;
}

h2 {
  font-size: 21px;
  text-align: center;
  margin: 40px;
  color: #98C4E6;
  font-weight: 700;
}

p {
  font-weight: 400;
  line-height: 2;
  font-size: 14px;
}

.iframe {
  width: 100%;
  height: 500px;
}

img {
  margin-bottom: 20px;
  border-radius: 5px;
}

.spinner {
  display: flex;
  justify-content: center;
  margin: 40px;
}
@media screen and (max-width:767px){
  .modal-content {
    width: 47%!important;
}
  .checkContainer div {
  margin: 0px 5px 10px 0!important;
}

}
</style>